import IntlMessageFormat from 'intl-messageformat';

let theLocale;

export function setLocale(locale) {
  theLocale = locale;
  window.theLocale = locale;
}

export function getLocale() {
  return window.theLocale;
}

export function intlMessageFormat(id, values = {}) {
  const { messages, locale } = getLocale();
  const text = new IntlMessageFormat(messages[id], locale).format(values);

  return text;
}
